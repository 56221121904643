import BaseModel from './BaseModel'
import {makeObservable, observable} from 'mobx'

export default class PriceModel extends BaseModel {
    constructor(store) {
    super(store);
    makeObservable(this, {
      min: observable,
      max: observable,
      currency: observable
    })
  }
  min = null;
  max = null;
  currency = 'KGS';
}
