import React, { useEffect, useRef } from "react";
import Choices from "choices.js/public/assets/scripts/choices";
import {observer} from 'mobx-react-lite'

const Select = ({ store, prop, options, onChange, placeholder = "Select an option" }) => {
  const selectRef = useRef(null);
  const choicesInstance = useRef(null);

  useEffect(() => {
    if (selectRef.current) {
      choicesInstance.current = new Choices(selectRef.current, {
        removeItemButton: true,
        searchEnabled: false,
        placeholder: true,
        placeholderValue: placeholder,
      });

      selectRef.current.addEventListener("change", (event) => {
        if (onChange) {
          onChange(event.target.value);
        } else {
          store.setProperty(prop, event.target.value)
        }
      });
    }

    return () => {
      if (choicesInstance.current) {
        choicesInstance.current.destroy();
      }
    };
  }, [store]);

  return (
    <select ref={selectRef} className="custom-select">
      {options.map((opt) => (
        <option key={opt.value} value={opt.value}>
          {opt.label}
        </option>
      ))}
    </select>
  );
};

export default observer(Select);
