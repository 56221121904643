import React from'react';
import {observer} from 'mobx-react-lite'
import CategoryTree from './CategoryTree'
import {Link} from 'react-router'

//Main navigation that turns into offcanvas on screens < 992px wide (lg breakpoint)
const RightSidebar = () => {
  return (
    <nav className="offcanvas offcanvas-start" id="navbarNav" tabIndex={-1} aria-labelledby="navbarNavLabel">
      <div className="offcanvas-header py-3">
        <h5 className="offcanvas-title" id="navbarNavLabel">Sooda</h5>
        <button type="button" className="btn-close" data-bs-dismiss="offcanvas" aria-label="Close"/>
      </div>
      <CategoryTree/>
      {/* Account button visible on screens < 768px wide (md breakpoint) */}
      <div className="offcanvas-header nav border-top px-0 py-3 mt-3 d-md-none">
        <Link className="nav-link justify-content-center w-100" to="/settings">
          <i className="ci-user fs-lg opacity-60 ms-n2 me-2"/>
        </Link>
      </div>
    </nav>
  );
}

export default observer(RightSidebar);