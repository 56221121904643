import React from'react';
import {observer} from 'mobx-react-lite'
import { Navigation, Pagination, Scrollbar, A11y } from 'swiper';
import 'swiper/css'
import 'swiper/css/navigation';
import 'swiper/css/pagination';
import 'swiper/css/scrollbar';
import {Swiper, SwiperSlide} from 'swiper/react'
import marketStore from '../../storage/MarketStore'

const Slider = () => {
  return (
    <Swiper
      modules={[Navigation, Pagination, Scrollbar, A11y]}
      spaceBetween={50}
      slidesPerView={1}
      navigation
      pagination={{ clickable: true }}
      scrollbar={{ draggable: true }}
      onSlideChange={() => console.log('slide change')}
      onSwiper={(swiper) => console.log(swiper)}
    >
      {marketStore.product.images.map((image, index) => (
        <SwiperSlide key={index}>
          <img src={image.data.original_webp_url} alt="product" />
        </SwiperSlide>
      ))}
    </Swiper>
  );
};

export default observer(Slider);