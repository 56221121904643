import {action, computed, makeObservable, observable} from 'mobx';
import BaseModel from './BaseModel';

export default class User extends BaseModel {

  constructor(store) {
    super(store);

    makeObservable(this, {
      id: observable,
      telegram_id: observable,
      fio: observable,
      first_name: observable,
      last_name: observable,
      username: observable,
      is_admin: observable,
      companies: observable,
      language: observable,
      country_id: observable,
      region_id: observable,
      district_id: observable,
      lat: observable,
      lon: observable,
      setCountry: observable,
      setRegion: observable,
      setDistrict: observable,
      region_tree: observable,
      setRegionTree: action,
      regionTree: computed,
      locationPath: computed,
      getCountry: computed,
      getRegion: computed,
      getDistrict: computed,
    });
  }

  id = 0;
  telegram_id = '';
  fio = '';
  first_name = '';
  last_name = '';
  username = '';
  language = 'ru';
  country_id = '';
  region_id = '';
  district_id = '';
  lat = 0;
  lon = 0;
  is_admin = false;
  companies = [];
  region_tree = [];

  setCountry(country_id) {
    this.country_id = country_id
    this.region_id = null
    this.district_id = null
    localStorage.setItem('country_id', country_id)
    localStorage.setItem('region_id', null)
    localStorage.setItem('district_id', null)
  }
  setRegion(country_id, region_id) {
    this.country_id = country_id
    this.region_id = region_id
    this.district_id = null
    localStorage.setItem('country_id', country_id)
    localStorage.setItem('region_id', region_id)
    localStorage.setItem('district_id', null)
  }

  setDistrict(country_id, region_id, district_id) {
    this.country_id = country_id
    this.region_id = region_id
    this.district_id = district_id
    localStorage.setItem('country_id', country_id)
    localStorage.setItem('region_id', region_id)
    localStorage.setItem('district_id', district_id)
  }
  get
  getCountry() {
    if (localStorage.getItem('country_id')) {
      this.country_id = parseInt(localStorage.getItem('country_id'))
    }
    return this.country_id
  }
  
  get
  getRegion() {
    if (localStorage.getItem('region_id')) {
      this.region_id = parseInt(localStorage.getItem('region_id'))
    }
    return this.region_id
  }

  get
  getDistrict() {
    if (localStorage.getItem('district_id')) {
      this.district_id = parseInt(localStorage.getItem('district_id'))
    }
    return this.district_id
  }
  

  setRegionTree(tree) {
    this.region_tree = tree
    localStorage.setItem('region_tree', JSON.stringify(tree))
  }

  get
  regionTree() {
    const rt = localStorage.getItem('region_tree')
    if (rt) {
      return JSON.parse(rt)
    } else {
      return []
    }
  }

  get
  locationPath() {
    let str = ''
    if (this.getRegion) {
      const reg = this.regionTree.find(r => r.id === this.getRegion)
      if (reg) {
        str = reg[this.language] || this.name
        const dist = reg.districts.find(r => r.id === this.getDistrict)
        if (dist) {
          str += ', '
          str += dist[this.language] || dist.name
        }
      }
    } else {
      str = this.language === 'ru'? 'Вся страна' : 'Бүткүл өлкө'
    }
    return str
  }
}
