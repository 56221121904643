import React from'react';
import {observer} from 'mobx-react-lite'
import marketStore from '../../../storage/MarketStore'
import appStore from '../../../storage/AppStore'

const Dropdown = ({property}) => {
  const sortedProperties = property.values.sort((a, b) => b.is_popular - a.is_popular);
  const firstFive = sortedProperties.slice(0, 5);
  const remaining = sortedProperties.slice(5);
  
  return (
    <div className="w-100 border rounded p-3 p-xl-4 mb-3 mb-xl-4">
      <h4 className="h6">{property[appStore.user.language] ||property.name}</h4>
      <div className="d-flex flex-column gap-1" style={{maxHeight: '200px', overflowY: 'auto'}}>
        {firstFive.map((value, index) => (
          <div key={index} className="d-flex align-items-center justify-content-between">
            <div className="form-check">
              <input type="checkbox" className="form-check-input" id={value.id} onChange={() => marketStore.query.setProperties(property, value)} value={value.id} />
              <label htmlFor={value.id}
                     className="form-check-label text-body-emphasis">{value[appStore.user.language] || value.value}</label>
            </div>
            <span className="text-body-secondary fs-xs">&nbsp;</span>
          </div>
        ))}
        {remaining.length > 0 && (
          <div className="accordion mb-n2">
            <div className="accordion-item border-0">
              <div className="accordion-collapse collapse" id={`more-brands-${property.id}`}>
                <div className="d-flex flex-column gap-1">
                  {remaining.map((value, index) => (
                    <div key={index} className="d-flex align-items-center justify-content-between">
                      <div className="form-check">
                        <input type="checkbox" className="form-check-input"
                               id={value.id} onChange={() => marketStore.query.setProperties(property, value)}/>
                        <label htmlFor={value.id}
                               className="form-check-label text-body-emphasis">{value[appStore.user.language] || value.value}</label>
                      </div>
                      <span className="text-body-secondary fs-xs">&nbsp;</span>
                    </div>
                  ))}
                </div>
              </div>
              <div className="accordion-header">
                <button type="button"
                        className="accordion-button w-auto fs-sm fw-medium collapsed animate-underline py-2"
                        data-bs-toggle="collapse" data-bs-target={`#more-brands-${property.id}`}
                        aria-expanded="false" aria-controls={`more-brands-${property.id}`}
                        aria-label="Show/hide more brands">
                                                        <span className="animate-target me-2"
                                                              data-label-collapsed={appStore.tr.more}
                                                              data-label-expanded={appStore.tr.less}>&nbsp;</span>
                </button>
              </div>
            </div>
          </div>
        )}
      </div>
      {marketStore.query.properties.some(p => p.property.id === property.id) && (
        <div className="d-flex">
          <button className="btn btn-sm btn-outline-primary ml-auto w-100"
                  onClick={() => marketStore.getProducts()}>{appStore.tr.show}</button>
        </div>
      )}
    </div>
  );
}

export default observer(Dropdown);