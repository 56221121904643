import * as React from 'react'
import systemStore from '../../storage/SystemStore'
import {observer} from "mobx-react-lite";

const NotFound = () => {

  return (
      <div>
          {systemStore.loading ?
              <div className={"d-flex justify-content-center align-items-center"}>
                  <span>loading...</span>
              </div>
              :
              <div style={{ textAlign: "center", marginTop: "50px" }}>
                  <h1>404</h1>
                  <p>Oops! Page not found.</p>
              </div>
          }
      </div>
  );
};

export default observer(NotFound);