import BaseModel from './BaseModel'
import {action, computed, makeObservable, observable} from 'mobx'
import PriceModel from "./Price";

export default class ProductsQuery extends BaseModel {
  constructor(store) {
    super(store);
    makeObservable(this, {
      q: observable,
      category_id: observable,
      price: observable,
      properties: observable,
      page: observable,
      sort: observable,
      country_id: computed,
      region_id: computed,
      district_id: computed,
      setProperties: action,
      clearAll: action,
      hasProperties: computed,
      resetPrice: action,
      removeProperty: action,
      location: computed,
    })
  }
  
  q = null
  price = new PriceModel()
  category_id = 1
  properties = []
  page = 1
  sort = 'new'
  
  setProperties(property, value) {
    const prop = this.properties.find(prop => prop.property?.id === property.id)
    if (!prop) {
      this.properties.push({property: property, values: [value]})
    } else {
      if (!prop.values.some(c => c.id === value.id)) {
        prop.values.push(value)
      } else {
        prop.values = prop.values.filter(c => c.id!== value.id)
      }
      if(prop.values.length === 0) {
        this.properties = this.properties.filter(prop => prop.property?.id !== property.id)
      }
    }
  }
  
  removeProperty(property) {
    this.properties = this.properties.filter(prop => prop.property?.id !== property.property.id)
  }
  
  clearAll() {
    this.price = new PriceModel()
    this.properties = []
    this.sort = 'new'
  }
  computed
  get hasProperties() {
    return this.properties.length > 0
  }
  resetPrice() {
    this.price = new PriceModel()
  }
  get country_id() {
    const c = localStorage.getItem('country_id')
    if (c) {return parseInt(c)}
    return null
  }
  get region_id() {
    const r = localStorage.getItem('region_id')
    if (r) {return parseInt(r)}
    return null
  }
  get district_id() {
    const d = localStorage.getItem('district_id')
    if (d) {return parseInt(d)}
    return null
  }
  get location() {
    return {country_id: this.country_id, region_id: this.region_id, district_id:this.district_id}
  }
}