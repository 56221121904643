import React from "react";
import {observer} from "mobx-react-lite";
import marketStore from "../../../storage/MarketStore";
import appStore from "../../../storage/AppStore";
import TextInput from "../../../ui/TextInput";

const Price = ({ property }) => {
  
  return (
    <div className="w-100 border rounded  p-3 p-xl-4 mb-3 mb-xl-4">
      <div className="d-flex align-items-center justify-content-between mb-2">
        <h4 className="h6 mb-2" id="slider-label">{appStore.tr.price}</h4>
      </div>
      <div className="d-flex align-items-center justify-content-end mb-2">
        {property.currencies.map((currency, index) => (
          <div key={index} className="w-auto mr-2">
            <button onClick={() => marketStore.query.price.setProperty('currency', currency.currency)}
                    className={`btn btn-sm ${marketStore.query.price.currency === currency.currency ? 'bg-primary text-white' : 'bg-secondary-outline'}`}>{currency.currency}</button>
          </div>
        ))}
      </div>
      <div className="d-flex align-items-center mb-2">
        <div className="position-relative  mx-1">
          <TextInput placeholder={appStore.tr.from} type="number" store={marketStore.query.price} prop="min"/>
        </div>
        <div className="position-relative  mx-1">
          <TextInput placeholder={appStore.tr.to} type="number" store={marketStore.query.price} prop="max"/>
        </div>
      </div>
      {(marketStore.query.price.min || marketStore.query.price.max) && (
        <div className="d-flex">
          <button className="btn btn-sm btn-outline-primary ml-auto w-100"
                  onClick={() =>marketStore.getProducts()}>{appStore.tr.show}</button>
        </div>
      )}
    </div>
  );
}
export default observer(Price);