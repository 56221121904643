import {action, autorun, computed, makeObservable, observable} from 'mobx'
import User from '../models/User'
import {post} from "../common/requester";

class AppStore {
  jwt = '';
  user = new User();
  countries = []
  regions = []
  districts = []
  languages = []
  tr = {}
  rand = 0
  sports = []
  region_tree = []
  
  constructor() {
    makeObservable(this, {
      jwt: observable,
      user: observable,
      countries: observable,
      regions: observable,
      region_tree: observable,
      districts: observable,
      languages: observable,
      tr: observable,
      rand: observable,
      sports: observable,
      setJwt: action,
      init: action,
      initUser: action,
      setLanguages: action,
      setTranslations: action,
      setCountries: action,
      setRegions: action,
      setDistricts: action,
      fetchRegions: action,
      fetchDistricts: action,
      updateCountry: action,
      updateRegion: action,
      updateDistrict: action,
      userCountry: computed,
      setLanguage: action,
      t: action,
    });
  }

  setProperty(key, value) {
    this[key] = value
  }
  
  setJwt(token) {
    this.jwt = token
    sessionStorage.setItem('jwt', this.jwt)
  }

  async init() {
    const res = await post('init', {}).catch((err) => console.error('--init error', err))
    if (res) {
      this.initUser(res.user)
      this.setLanguages(res.languages)
      this.setTranslations(res.translations)
      this.setCountries(res.countries)
      if (res.regions) {
        this.setRegions(res.regions.items)
      }
      if (res.districts) {
        this.setDistricts(res.districts.items)
      }
    }

    if (this.user.regionTree.length === 0) {
      const rt = await post('region_tree', {}).catch(() => [])
      this.user.setRegionTree(rt.tree || [])
    }
  }
  
  initUser(json) {
    this.user.fromJson(json)
    if (this.user.id) {
      localStorage.setItem('country_id', this.user.country_id)
      localStorage.setItem('region_id', this.user.region_id)
      localStorage.setItem('district_id', this.user.district_id)
    }
  }

  setCountries(data) {
    sessionStorage.setItem('countries', JSON.stringify(data))
    this.countries = data
  }

  async fetchRegions() {
    if (this.user.id && this.user.country_id) {
      const r = await post('regions', {country_id: this.user.country_id, 'page_size': 200}).catch(()=>{})
      this.setRegions(r.items)
      if(!this.regions.some(r => r.id === this.user.region_id)) {
        this.user.setProperty('region_id', undefined)
        this.user.setProperty('district_id', undefined)
        this.setDistricts([])
      }
    }
  }

  setRegions(data) {
    sessionStorage.setItem('regions', JSON.stringify(data))
    this.regions = data
  }

  async fetchDistricts() {
    if (this.user.id && this.user.region_id) {
      const response = await post('districts', {region_id: this.user.region_id, 'page_size': 200}).catch(() => {})
      this.setDistricts(response.items)
      if(!this.districts.some(d => d.id === this.user.district_id)) {
        this.user.setProperty('district_id', undefined)
      }
    }
  }

  setDistricts(data) {
    sessionStorage.setItem('districts', JSON.stringify(data))
    this.districts = data
  }


  async updateCountry(country_id) {
    if (this.user.id) {
      await post('country', {'country_id': country_id}).catch((err)=>console.log(err))
      await this.fetchRegions()
    } else {
      this.user.setCountry(country_id)
    }
  }

  async updateRegion(region_id) {
    if (this.user.id && this.user.country_id) {
      await post('region', {'region_id': region_id}).catch((err)=>console.log(err))
      await this.fetchDistricts()
    }
  }

  async updateDistrict(district_id) {
    if (this.user.id && this.user.district_id) {
      await post('district', {'district_id': district_id}).catch((err)=>console.log(err))
    }
  }

  get
  userCountry() {
    return this.countries.find(c => c.id === this.user.country_id)
  }

  async setLanguage(language) {
    console.log('--language', language)
    this.user.setProperty('language', language)
    sessionStorage.setItem('language', language)
    if (this.user.id) {
      await post('language', {'id': language}).catch((err) => {console.log(err)})
    }
    const response = await post('translations', {language: language}).catch(err => console.log(err))
    this.setTranslations(response.t)
    const response2 = await post('countries').catch(err => console.log(err))
    this.setCountries(response2.countries)
    if(this.user.id && this.user.country_id) {
      await this.fetchRegions()
    }
    if (this.user.id && this.user.region_id) {
      await this.fetchDistricts()
    }
  }

  setLanguages(data) {
    sessionStorage.setItem('languages', JSON.stringify(data))
    this.languages = data
  }

  get
  userLanguage() {
    return this.languages.find(l => l.id === this.user.language) || this.languages[0]
  }
  
  setTranslations(data) {
    sessionStorage.setItem('translations', JSON.stringify(data))
    this.setProperty('tr', data)
  }

  t(key) {
    return this.tr[key] || key
  }

  locationPath(country_id, region_id, district_id) {
    //console.log('--locationPath', country_id, region_id, district_id)
    // let str = ''
    // if (region_id) {
    //   const region = this.regionTree.find(r => r.id === region_id)
    //   if (region) {
    //     str = region[this.user.language]
    //     if (district_id) {
    //       const district = region.districts.find(d => d.id === district_id)
    //       if (district) {
    //         str += ', '
    //         str += district[this.user.language] || district.name
    //       }
    //     }
    //   }
    // } else {
    //   str = this.tr.whole_country
    // }
    // return str
    return country_id+", "+region_id+", "+district_id+" something"
  }
}
const appStore = new AppStore();
export default appStore;