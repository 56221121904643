import React from 'react';
import {observer} from 'mobx-react-lite'
import marketStore from '../../storage/MarketStore'
import {Link} from 'react-router'
import appStore from '../../storage/AppStore'

const CategoryTree = () => {
  
  return (
    <div className="offcanvas-body pt-3 pb-4 py-lg-0 mx-lg-auto">
      <ul className="navbar-nav position-relative">
        <li className="nav-item dropdown position-static py-lg-2 me-lg-n1 me-xl-0">
          <a className="nav-link dropdown-toggle active" aria-current="page" href="#" role="button"
             data-bs-toggle="dropdown" data-bs-trigger="hover" aria-expanded="false">{appStore.tr.categories || 'Категории'}</a>
          
          <div className="dropdown-menu rounded-4 p-4"
               style={{"--cz-dropdown-spacer": ".875rem", "maxWidth": "600px", "overflowX": "auto"}}>
            <div className="d-flex flex-column flex-lg-row gap-4">
              {marketStore.categories.map((category, index1) => (
                <div key={index1} style={{"minWidth": "190px"}}>
                  <div className="h6 mb-2"><Link to={`/category/${category.id}`}>{category[appStore.user.language] || category.name}</Link></div>
                  {category.children.length > 0 && (
                    <ul className="nav flex-column gap-2 mt-0">
                      {category.children.map((child, index2) => (
                        <li key={index2} className="d-flex w-100 pt-1">
                          <Link
                            className="nav-link animate-underline animate-target d-inline fw-normal text-truncate p-0"
                            to={`/category/${child.id}`}>{child[appStore.user.language] || child.name}</Link>
                        </li>
                      ))}
                      {category.total > 10 && (
                        <li className="d-flex w-100 pt-1">
                          <Link
                            className="nav-link animate-underline animate-target d-inline fw-normal text-truncate p-0"
                            to={`/category/${category.id}`}>{appStore.tr.more || 'More'} ({category.total - 10})</Link>
                        </li>
                      )}
                    </ul>
                  )}
                </div>
              ))}
            </div>
          </div>
        
        </li>
        <li className="nav-item dropdown position-static py-lg-2 me-lg-n1 me-xl-0">
          <a className="nav-link dropdown-toggle active" aria-current="page" href="#" role="button"
             data-bs-toggle="dropdown" data-bs-trigger="hover" aria-expanded="false">{appStore.user.locationPath}</a>

          <div className="dropdown-menu rounded-4 p-4"
               style={{"--cz-dropdown-spacer": ".875rem", "maxWidth": "600px", "overflowX": "auto"}}>
            <div className="d-flex flex-column flex-lg-row gap-4">
              <div style={{"minWidth": "190px"}}>
                <div className="h6 mb-2"><a href="" onClick={(e)=>{e.preventDefault(); appStore.user.setCountry(12); marketStore.getProducts()}}>{appStore.tr.whole_country}</a></div>
                <div className="h6 mb-2"><a href="" onClick={(e)=>{e.preventDefault(); appStore.user.setDistrict(12, 209, 103184); marketStore.getProducts()}}>Бишкек</a></div>
                <div className="h6 mt-2 mb-2"><a href="" onClick={(e)=>{e.preventDefault(); appStore.user.setDistrict(12, 214, 103218); marketStore.getProducts()}}>Ош</a></div>
              </div>
              {appStore.user.regionTree.map((region, index1) => (
                  <div key={index1} style={{"minWidth": "190px"}}>
                    <div className="h6 mb-2"><a href="" onClick={(e)=>{e.preventDefault(); appStore.user.setRegion(12, region.id); marketStore.getProducts()}}>{region[appStore.user.language] || region.name}</a></div>
                    {region.districts.length > 0 && (
                        <ul className="nav flex-row gap-2 mt-0" style={{"maxHeight": "200px", "overflowY": "auto"}}>
                          {region.districts.map((district, index2) => (
                            <li key={index2} className="d-flex w-100 pt-1">
                              <a
                                  href=""
                                  onClick={(e)=>{e.preventDefault(); appStore.user.setDistrict(12, region.id, district.id); marketStore.getProducts()}}
                                  className="nav-link animate-underline animate-target d-inline fw-normal text-truncate p-0"
                                >{district[appStore.user.language] || district.name}</a>
                            </li>
                          ))}
                        </ul>
                    )}
                  </div>
              ))}
            </div>
          </div>
        </li>
      </ul>
    </div>
  )
}

export default observer(CategoryTree);