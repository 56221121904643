import {action, autorun, makeObservable, observable} from 'mobx'
import {post} from '../common/requester'
import Category from '../models/Category'
import Products from "../models/Products";
import ProductsQuery from '../models/ProductsQuery'
import Product from "../models/Product";

class MarketStore {
  category = new Category()
  categories = []
  parent_id = 1
  products = new Products()
  query = new ProductsQuery()
  properties = []
  product = new Product()

  constructor() {
    makeObservable(this, {
      category: observable,
      categories: observable,
      products: observable,
      query: observable,
      product: observable,
      getCategories: action,
      setCategories: action,
      setCategory: action,
      getCategory: action,
      getProducts: action,
      getProductsMore: action,
      setProducts: action,
      setProductItems: action,
      getProperties: action,
      setProperties: action,
      getProduct: action,
      setProduct: action,
    })
    autorun(() => {
      console.log('--marketStore autorun')
      this.getCategories()
    })
  }

  setCategory(category) {
    this.category.fromJson(category)
  }
  setCategories(categories) {
    this.categories = categories
  }
  async getCategory(id) {
    const response = await post('market/category', {id}).catch((err) => console.error('--category error',err))
    if (response  && response.result === 0) {
      this.setCategory(response)
      this.query.clearAll()
      this.getProperties()
    }
    return Promise.resolve(response)
  }
  async getCategories() {
    const cat = localStorage.getItem('categories')
    if (cat) {
      this.setCategories(JSON.parse(cat))
      return Promise.resolve(true)
    }
    const response = await post('market/categories', {parent_id: this.category.id}).catch((err) => console.error('--categories error',err))
    if (response  && response.result === 0) {
      this.setCategories(response.categories)
      localStorage.setItem('categories', JSON.stringify(response.categories))
    }
    return Promise.resolve(response)
  }
  setProducts(products) {
    this.products = products
  }

  setProductItems(items) {
    const all = this.products.items.concat(items)
    this.setProducts({...this.products, items: all})
  }
  
  fetchProducts = async (category_id, append) => {
    const response = await post('market/products', {
      category_id: category_id,
      query: this.query,
      location: this.query.location
    }).catch((err) => console.error('--products error',err))
    
    if (response  && response.result === 0) {
      if (append) {
        this.setProductItems(response.items)
      } else {
        this.setProducts(response)
      }
    }
    return Promise.resolve(response)
  }
  
  async getProducts(category_id) {
    if (!category_id) {
      category_id = this.category.id
    }
    this.query.setProperty('page', 1)
    return this.fetchProducts(category_id, false)
  }
  
  async getProductsMore(category_id) {
    if (!category_id) {
      category_id = this.category.id
    }
    if (this.products.page < this.products.page_count) {
      this.query.setProperty('page', this.query.page + 1)
      return this.fetchProducts(category_id, true)
    }
  }

  setProperties(properties) {
    this.properties = properties
  }

  async getProperties() {
    const response = await post('market/category_properties', {category_id: this.category.id}).catch((err) => console.error('--properties error',err))

    if (response  && response.properties) {
      this.setProperties(response.properties)
    }
    return Promise.resolve(response)
  }

  setProduct(product) {
    this.product.fromJson(product)
  }

  async getProduct(id) {
    const response = await post('market/product', {id}).catch((err) => console.error('--product error',err))

    if (response  && response.result === 0) {
      this.setProduct(response.product)
    }
    return Promise.resolve(response)
  }
}

const marketStore = new MarketStore();
export default marketStore;