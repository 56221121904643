import BaseModel from './BaseModel'
import {makeObservable, observable} from 'mobx'

export default class Products extends BaseModel {
    constructor(store) {
        super(store);
        makeObservable(this, {
            items: observable,
            items_per_page: observable,
            page: observable,
            page_count: observable,
            total: observable,
        })
    }
    items = [];
    items_per_page = 20;
    page = 1;
    page_count = 1;
    total = 0;
}
