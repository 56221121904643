export const APPLICATION = {"PROD":true,"DEV":false};


export const getBaseUrl = () => {
  if (APPLICATION.PROD) {
    return 'https://sooda.online:7777/api';
  }

  // return 'https://28e9-95-87-73-249.ngrok-free.app/api';
  return 'http://localhost:8622/api'
};

export const botUsername = () => {
  if(APPLICATION.PROD) {
    return 'soodaonline_bot'
  }
  return 'soodaonline_bot'
}
