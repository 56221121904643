import {useTelegram} from './hooks/useTelegram'
import Header from './components/Header/Header'
import {Route, Routes} from 'react-router-dom'
import Client from './components/Client/Client'
import {observer} from 'mobx-react-lite'
import appStore from './storage/AppStore'
import {useEffect} from 'react'
import Notification from './components/Notification/Notification'
import Loading from './components/Loading/Loading'
import {useSearchParams} from 'react-router'
import NotFound from "./components/NotFound/NotFound";
import Footer from "./components/Footer/Footer";
import Category from "./components/Category/Category";
import "choices.js/public/assets/styles/choices.css";
import './common/style.css'
import Product from "./components/Product/Product";


function App() {
    const {tg, isValid, onToggleTheme} = useTelegram();
    const [searchParams] = useSearchParams();
    
    useEffect(() => {
        const value = searchParams.get("jwt");
        if (value) {
          appStore.setJwt(value)
        }
        }, []
    );
    
    useEffect(() => {
        tg?.ready();
        appStore.init();
        if (isValid()){
            tg.BackButton.show();
            tg.BackButton.onClick(() => {
                if (window.history.state.idx === 0) {
                    tg?.close()
                } else {
                    window.history.back()
                }
            })
            tg.onEvent('themeChanged', onToggleTheme);
            tg.onEvent('settingsButtonClicked', () => {
                tg.showAlert('settings opened')
            });
            return () => tg.offEvent('themeChanged', onToggleTheme);
        }
    }, [tg]);
    
    return (
      <>
          <Loading/>
          <Header/>
          <main className="content-wrapper">
              <div className="container pb-5 mb-2 mb-sm-3 mb-lg-4 mb-xl-5">
              {/*{appStore.user && appStore.user.id &&*/}
              {/*  <Routes>*/}
              {/*      <Route path={'settings'} element={<Settings/>}/>*/}
              {/*      /!*{appStore.user.is_admin &&*!/*/}
              {/*      /!*  <>*!/*/}
              {/*      /!*      <Route path={'/admin'} element={<AdminHome/>}/>*!/*/}
              {/*      /!*      <Route path={'/admin/companies'} element={<AdminCompanies/>}/>*!/*/}
              {/*      /!*      <Route path={'/admin/new_company'} element={<NewCompany/>}/>*!/*/}
              {/*      /!*      <Route path={'/admin/edit_company/:id'} element={<EditCompany/>}/>*!/*/}
              {/*      /!*  </>*!/*/}
              {/*      /!*}*!/*/}
              {/*  </Routes>*/}
              {/*}*/}
                <Routes>
                    <Route index element={<Client/>}/>
                    <Route path="category/:id" element={<Category/>}/>
                    <Route path="product/:id" element={<Product/>}/>
                    <Route path="*" element={<NotFound/>}/>
                </Routes>
              </div>
          </main>
          <Footer/>
          <Notification/>
      </>
    );
}

export default observer(App);
