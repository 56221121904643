import React from "react";
import {observer} from 'mobx-react-lite'
import {Link} from 'react-router'
import appStore from '../../storage/AppStore'
import marketStore from '../../storage/MarketStore'

const Breadcrumb = () => {
  return (
    <ol className="breadcrumb mt-2 mb-2">
      <li className="breadcrumb-item"><Link to="/">{appStore.tr.home}</Link></li>
      {marketStore.category.breadcrumb.map((item, index) => (
        <li key={index} className="breadcrumb-item">
          <Link to={`/category/${item.id}`}>{item[appStore.user.language] || item.name}</Link>
        </li>
      ))}
      {marketStore.category.id !== 1 &&
        <li className="breadcrumb-item">
          <b className="text-primary">{marketStore.category[appStore.user.language] || marketStore.category.name}</b>
        </li>
      }
    </ol>
  );
}

export default observer(Breadcrumb);