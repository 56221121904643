import React, {useEffect} from 'react';
import {observer} from 'mobx-react-lite'
import marketStore from '../../storage/MarketStore'
import {Link, useParams} from 'react-router'
import SelectedFilter from "./SelectedFilter";
import SideFilter from "./SideFilter";
import TextInput from "../../ui/TextInput";
import appStore from "../../storage/AppStore";
import Pagination from './Pagination'
import Listing from './Listing'

const Products = () => {
  const args = useParams();
  useEffect(() => {
    marketStore.getProducts(args['id']);
  }, [args])
  return (
    <>
      {/*<TextInput store={marketStore.query} prop="q" placeholder={appStore.tr.search}/>*/}
        <div className="position-relative mb-3 mt-2">
            <i className="ci-search position-absolute top-50 start-0 translate-middle-y ms-3">&nbsp;</i>
            <input
                type="search"
                className="brands-search form-control form-icon-start"
                placeholder={appStore.tr.search}
                value={marketStore.query.q || ''}
                onChange={(e) => marketStore.query.setProperty('q', e.target.value)}
                onKeyPress={(e) => {if (e.key === 'Enter') {e.preventDefault(); marketStore.getProducts();}}}
            />
                <button
                    onClick={() => marketStore.getProducts()}
                    className="btn btn-sm btn-outline-secondary w-auto border-0 p-1 position-absolute top-50 end-0 translate-middle-y me-2 opacity-75">
                    {appStore.tr.search}
                </button>
            </div>
      <SelectedFilter/>
      <div className="row">
        {marketStore.category.id !== 1 && (
          <aside className="col-lg-3">
            <SideFilter/>
          </aside>
        )}
        <div className={marketStore.category.id === 1 ? "col-lg-12" : "col-lg-9"}>
          <Listing/>
        </div>
      </div>
    </>
  );
}

export default observer(Products);