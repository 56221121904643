import React from'react';
import {observer} from 'mobx-react-lite'
import marketStore from '../../storage/MarketStore'

const Pagination = () => {
  if (marketStore.products.page_count <= 1) {
    return null
  }
  return (
    <nav className="pt-4 mt-2 mt-sm-3" aria-label="Pagination">
      <ul className="pagination pagination-lg">
        <li className="page-item me-auto">
          <a className="page-link d-flex align-items-center h-100 fs-lg px-2" href="#!"
             aria-label="Previous page">
            <i className="ci-chevron-left mx-1"/>
          </a>
        </li>
        <li className="page-item active" aria-current="page">
            <span className="page-link">
              1
              <span className="visually-hidden">(current)</span>
            </span>
        </li>
        <li className="page-item">
          <a className="page-link" href="#!">2</a>
        </li>
        <li className="page-item">
          <a className="page-link" href="#!">3</a>
        </li>
        <li className="page-item">
          <span className="page-link pe-none">...</span>
        </li>
        <li className="page-item">
          <a className="page-link" href="#!">16</a>
        </li>
        <li className="page-item ms-auto">
          <a className="page-link d-flex align-items-center h-100 fs-lg px-2" href="#!"
             aria-label="Next page">
            <i className="ci-chevron-right mx-1"/>
          </a>
        </li>
      </ul>
    </nav>
  )
}

export default observer(Pagination);