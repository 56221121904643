import BaseModel from './BaseModel'
import {makeObservable, observable} from 'mobx'

export default class Product extends BaseModel {
    constructor(store) {
        super(store);
        makeObservable(this, {
            id: observable,
            title: observable,
            description: observable,
            price: observable,
            currency: observable,
            country: observable,
            region: observable,
            district: observable,
            category: observable,
            images: observable,
            properties: observable,
            created_at: observable,
            updated_at: observable,
            views: observable,
            user: observable,
            url: observable,
            lat: observable,
            lng: observable,
            is_vip: observable,
            is_negotiable: observable,
            is_premium: observable
        })
    }
    id = 0;
    title = null;
    description = null;
    price = null;
    old_price = null
    currency = null;
    country = null;
    region = null;
    district = null;
    category = null;
    images = [];
    properties = [];
    created_at = null;
    updated_at = null;
    views = null;
    user = null;
    url = null;
    lat= null;
    lng= null;
    is_vip = false;
    is_negotiable = false;
    is_premium = false;
}
