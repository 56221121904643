import {observer} from "mobx-react-lite";
import marketStore from "../../storage/MarketStore";
import Select from '../../ui/Select'
import appStore from "../../storage/AppStore";
import systemStore from '../../storage/SystemStore'
import React from 'react'

const SelectedFilter = () => {
  const sortLabel = () => {
    if(marketStore.query.sort === 'new') {
      return appStore.tr.newest_first
    } else if(marketStore.query.sort === 'cheap') {
      return appStore.tr.cheapest_first
    } else {
      return appStore.tr.expensive_first
    }
  }
  if(systemStore.loading && !marketStore.products.total) {
    return <div className="row mt-2 mb-2">&nbsp;</div>
  }
    return (
      <div className="row mt-2 mb-2">
          <div className="col-lg-9 col-sm-6">
              <div className="d-md-flex align-items-start">
                  <div
                    className="h6 fw-normal text-nowrap translate-middle-y mt-3 mb-0 me-4">{appStore.tr.found}: <span
                    className="fw-semibold">{marketStore.products.total}</span>
                  </div>
                  <div className="d-flex flex-wrap gap-2">
                      {(marketStore.query.price.max || marketStore.query.price.min) && (
                        <button type="button" className="btn btn-sm btn-secondary" onClick={() => {
                            marketStore.query.resetPrice()
                        }}>
                            <i className="ci-close fs-sm me-1">&nbsp;</i>
                            {marketStore.query.price.currency} {marketStore.query.price.min || 0} - {marketStore.query.price.max || '∞'}
                        </button>
                      )}
                      {marketStore.query.properties.map((property, index) => (
                        <button
                          key={index}
                          type="button"
                          className="btn btn-sm btn-secondary"
                          onClick={() => {
                            marketStore.query.removeProperty(property);
                            marketStore.getProducts();
                        }}
                        >
                            <i className="ci-close fs-sm ms-n1 me-1">&nbsp;</i>
                            {property.property[appStore.user.language] || property.property.name} - {property.values.map((value, index) => {
                            return value[appStore.user.language] || value.value
                        }).join(", ")}
                        </button>
                      ))}
                      {(marketStore.query.price.max || marketStore.query.price.min || marketStore.query.hasProperties) > 0 && (
                        <button type="button" onClick={() => {
                            marketStore.query.clearAll();
                            marketStore.getProducts();
                        }}
                                className="btn btn-sm btn-secondary bg-transparent border-0 text-decoration-underline px-0 ms-2">
                            {appStore.tr.clear}
                        </button>
                      )}
                  </div>
              </div>
          </div>
          <div className="col-lg-3 col-sm-6 mt-3 mt-lg-0">
            <a className="nav-link dropdown-toggle active" aria-current="page" href="#" role="button"
             data-bs-toggle="dropdown" data-bs-trigger="hover" aria-expanded="false">{sortLabel()}</a>
            <div className="dropdown-menu rounded-4 p-4"
                 style={{"--cz-dropdown-spacer": ".875rem", "maxWidth": "600px", "overflowX": "auto"}}>
              <ul className="list-unstyled mb-0">
                <li><a className="dropdown-item" href="#" onClick={() => {
                    marketStore.query.setProperty('sort', 'new');
                    marketStore.getProducts();
                }}>{appStore.tr.newest_first} {marketStore.query.sort === 'new' && <i className="ci-check pt-2 ml-2">&nbsp;</i>}</a></li>
                <li><a className="dropdown-item" href="#" onClick={() => {
                    marketStore.query.setProperty('sort', 'cheap');
                    marketStore.getProducts();
                }}>{appStore.tr.cheapest_first} {marketStore.query.sort === 'cheap' && <i className="ci-check pt-2 ml-2">&nbsp;</i>}</a></li>
                <li><a className="dropdown-item" href="#" onClick={() => {
                    marketStore.query.setProperty('sort', 'expensive');
                    marketStore.getProducts();
                }}>{appStore.tr.expensive_first} {marketStore.query.sort === 'expensive' && <i className="ci-check pt-2 ml-2">&nbsp;</i>}</a></li>
              </ul>
            </div>
          </div>
      </div>
    )
}
export default observer(SelectedFilter);