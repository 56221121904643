import {observer} from 'mobx-react-lite'
import marketStore from '../../storage/MarketStore'
import {Link, useParams} from 'react-router'
import React, {useEffect, useRef} from 'react'
import appStore from '../../storage/AppStore'

const Listing = () => {
  const args = useParams()
  const observerRef = useRef(null);
  
  useEffect(() => {
    const observer = new IntersectionObserver(
      (entries) => {
        if (entries[0].isIntersecting) {
          marketStore.getProductsMore()
        }
      },
      {root: null, rootMargin: "100px", threshold: 0.1}
    );
    
    if (observerRef.current) {
      observer.observe(observerRef.current);
    }
    
    return () => {
      if (observerRef.current) {
        observer.unobserve(observerRef.current);
      }
    };
  }, []);
  
  return (
    <div className="row row-cols-1 row-cols-md-3 g-4 pb-3 mb-3">
      {marketStore.products.items.map((product, index) => (
        <div key={index} className="col">
          <div
            className="card h-100 animate-underline hover-effect-opacity hover-effect-scale rounded-4 overflow-hidden">
            <div
              className="card-img-top position-relative bg-body-tertiary overflow-hidden">
              <Link className="ratio d-block hover-effect-target"
                    style={{
                      backgroundImage: `url(${product.image.path})`,
                      backgroundSize: 'cover',
                      backgroundPosition: 'center',
                      width: '370px',
                      height: '220px'
                    }}
                    to={`/product/${product.id}`}>
                {/*<img src={product.image.path} alt="Image"/>*/}
                &nbsp;
              </Link>
              <div
                className="position-absolute top-0 end-0 z-2 hover-effect-target opacity-0 pt-1 pt-sm-0 pe-1 pe-sm-0 mt-2 mt-sm-3 me-2 me-sm-3">
                <button type="button"
                        className="btn btn-sm btn-icon btn-light bg-light border-0 rounded-circle animate-pulse"
                        aria-label="Add to wishlist">
                  <i className="ci-heart animate-target fs-sm">&nbsp;</i>
                </button>
              </div>
            </div>
            <div className="card-body p-3">
              <div className="d-flex align-items-center justify-content-between mb-2">
                <>
                  {product.price && <div className="h6 mb-0">{product.price} {product.currency} {product.old_price && <span className="text-decoration-line-through fs-xs">{`${product.old_price} ${product.currency}`}</span>}</div>}
                  {!product.price && <div className="h6 mb-0">{appStore.tr.negotiable_price}</div>}
                </>
                <div className="text-muted fs-xs">{product.updated_at || product.created_at}</div>
              </div>
              <div className="d-flex align-items-center justify-content-between mb-2">
                <div>
                  <Link className="nav-link" to={`/category/${product.category.id}`}>{product.category.name}</Link>
                </div>
                <div>
                  <a href=""
                     className="nav-link"
                     onClick={(e) => {
                    e.preventDefault();
                    appStore.user.setDistrict(product.country?.id, product.region?.id, product.district?.id);
                    marketStore.getProducts()
                  }}>{product.district?.name}</a>
                </div>
              </div>
                  <Link className="nav-link p-0 mb-2" to={`/product/${product.id}`}>
                    <span className="animate-target">{product.title}</span>
                  </Link>
              <div
                className="d-flex flex-wrap align-items-center justify-content-between gap-2 mt-4">
                <div className="nav align-items-center gap-1 fs-xs">
                  <Link className="nav-link fs-xs text-body gap-1 p-0" href="users/1">
                    {product.user.username}
                  </Link>
                  <div className="text-body-secondary"><i className="ci-phone"/></div>
                  <a className="nav-link fs-xs text-body p-0"
                     href={`https://t.me/${product.user.phone}`} target="_blank">{product.user.phone}</a>
                </div>
                <div className="fs-xs text-body-secondary"><i
                  className="ci-eye"/> {product.views}</div>
              </div>
            </div>
          </div>
        </div>
      ))}
      <div ref={observerRef} style={{height: "1px"}}>&nbsp;</div>
    </div>
  );
}

export default observer(Listing);