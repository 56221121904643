import React from'react';
import {observer} from 'mobx-react-lite'
import marketStore from '../../storage/MarketStore'
import {Link} from 'react-router'
import appStore from '../../storage/AppStore'

const SubCategories = () => {
  if(!marketStore.category.children.length) {
    return null
  }
  return (
    <div className="overflow-x-auto">
      <ul className="nav nav-pills flex-nowrap gap-2 text-nowrap pb-3">
        {marketStore.category.children.map((child, index) => (
          <li key={index} className="nav-item">
            <Link className="nav-link"  to={`/category/${child.id}`}>{child[appStore.user.language] ||child.name}</Link>
          </li>
        ))}
      </ul>
    </div>
  );
};

export default observer(SubCategories);