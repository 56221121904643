import { useTelegram} from '../hooks/useTelegram'
import {getBaseUrl} from "./config";
import systemStore from '../storage/SystemStore'

const {tg, isValid} = useTelegram()

const getParameterByName = (name, url = window.location.href) => {
    name = name.replace(/[\[\]]/g, '\\$&');
    var regex = new RegExp('[?&]' + name + '(=([^&#]*)|&|#|$)'),
        results = regex.exec(url);
    if (!results) return null;
    if (!results[2]) return '';
    return decodeURIComponent(results[2].replace(/\+/g, ' '));
}

const _jwt = getParameterByName('jwt') || sessionStorage.getItem('jwt')

const handleMessage = (msg) => {
    if(isValid()) {
        tg.showAlert(msg)
        // systemStore.setError(msg)
    } else {
        systemStore.setError(msg)
    }
    return Promise.reject(msg)
}

export const post = async (endpoint, data) => {
    const d = new Date();
    let diff = d.getTimezoneOffset();
    const lang = sessionStorage.getItem('language') || 'ru'
    const url = `${getBaseUrl()}/${endpoint}?lang=${lang}`;
    const options = {
        method: 'POST',
        mode: 'cors',
        credentials: 'include',
        headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json;charset=UTF-8',
            'X-Timezone-Offset': diff
        },
        body: JSON.stringify(data)
    }
    if(_jwt) {
        options.headers['Authorization'] = `Bearer ${_jwt}`
    }
    try {
        systemStore.setLoading(true)
        let response = await fetch(url, options)
        systemStore.setLoading(false)
        if (response.ok) {
            let json =  await response.json();
            if(json.result !== 0) {
                return handleMessage(json.message)
            }
            return json;
        } else {
            return handleMessage('Network Error')
        }
    } catch(err) {
        systemStore.setLoading(false)
        return handleMessage('Network Error')
    } finally {
        systemStore.setLoading(false)
    }


}