import {observer} from "mobx-react-lite";
import {useParams} from "react-router";
import marketStore from "../../storage/MarketStore";
import React, {useEffect} from "react";
import Breadcrumb from './Breadcrumb'
import SubCategories from './SubCategories'
import Products from './Products'
import appStore from '../../storage/AppStore'

const Category = () => {
  const args = useParams();

  useEffect(() => {
    marketStore.getCategory(args['id']);
  }, [args['id']]);

  return <div>
    <Breadcrumb/>
    <SubCategories/>
    <Products/>
  </div>;
};

export default observer(Category);