import {action, autorun, makeObservable, observable} from 'mobx'

class SystemStore {
  loading = false;
  mode = 'light';
  message = '';
  messageType = '';
  
  constructor() {
    makeObservable(this, {
      loading: observable,
      mode: observable,
      message: observable,
      messageType: observable,
      setLoading: action,
      setError: action,
      setSuccess: action,
      clearMessage: action,
      setMode: action,
      toggleMode: action
    });
    autorun(() => {
      console.log('--systemstore autorun')
      this.setMode()
    })
  }
  
  setLoading(loading) {
    this.loading = loading
  }

  setMode() {
    this.mode = sessionStorage.getItem('mode') || 'light'
  }

  
  toggleMode(mode) {
    this.mode = mode
    sessionStorage.setItem('mode', this.mode)
  }
  
  setError(msg) {
    this.message = msg
    this.messageType = 'error'
  }
  
  setSuccess(msg) {
    this.message = msg
    this.messageType = 'success'
  }
  
  clearMessage() {
    this.message = ''
    this.messageType = ''
  }
}
const messageStore = new SystemStore();
export default messageStore;
