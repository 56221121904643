import React from "react";
import {observer} from 'mobx-react-lite'
import systemStore from "../storage/SystemStore";

const TextInput = ({ placeholder, store, prop, rest }) => {
    return (
        <>
            <input
                disabled={systemStore.loading}
                placeholder={placeholder}
                value={store[prop]}
                onChange={(e) => {
                    store.setProperty(prop, e.target.value);
                }}
                className="form-control"
                {...rest}
            />
        </>
    )
}

export default observer(TextInput);