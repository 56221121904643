import React from "react";
import {observer} from "mobx-react-lite";
import marketStore from "../../storage/MarketStore";
import Price from "./Filter/Price";
import Dropdown from './Filter/Dropdown'
import appStore from "../../storage/AppStore";

const SideFilter = () => {
    return (
        <div className="offcanvas-lg offcanvas-start" id="filterSidebar">
            <div className="offcanvas-header py-3">
                <h5 className="offcanvas-title">{appStore.tr.filter}</h5>
                <button type="button" className="btn-close" data-bs-dismiss="offcanvas"
                        data-bs-target="#filterSidebar" aria-label="Close">&nbsp;</button>
            </div>
            <div className="offcanvas-body flex-column pt-2 py-lg-0">
                {marketStore.products.length}
                {marketStore.properties.map((property, index) => (
                    <React.Fragment key={index}>
                        {property.kind === "price" && <Price key={index} property={property}/>}
                        {property.kind === "dropdown" && <Dropdown key={index} property={property}/>}
                        {property.kind === "input" && <div>&nbsp;</div>}
                    </React.Fragment>
                ))}
            </div>
        </div>
    )
}
export default observer(SideFilter)