import BaseModel from './BaseModel'
import {makeObservable, observable} from 'mobx'

export default class Category extends BaseModel {
    constructor(store) {
    super(store);
    makeObservable(this, {
      id: observable,
      name: observable,
      kg: observable,
      ru: observable,
      parent_id: observable,
      parent_name: observable,
      url: observable,
      color: observable,
      depth: observable,
      image: observable,
      order: observable,
      active: observable,
      ads_count: observable,
      feed_type: observable,
      color_dark: observable,
      icon_class: observable,
      icon_image: observable,
      panel_type: observable,
      show_image: observable,
      show_price: observable,
      is_duplicate: observable,
      posting_type: observable,
      search_names: observable,
      api_list_type: observable,
      singular_name: observable,
      breadcrumb: observable,
      children: observable,
    })
  }
  id = 1;
  name = null;
  kg = null;
  ru = null;
  parent_id = null;
  parent_name = null;
  url = null;
  color = null;
  depth = null;
  image = null;
  order = null;
  active = null;
  ads_count = null;
  feed_type = null;
  color_dark = null;
  icon_class = null;
  icon_image = null;
  panel_type = null;
  show_image = null;
  show_price = null;
  is_duplicate = null;
  posting_type = null;
  search_names = null;
  api_list_type = null;
  singular_name = null;
  breadcrumb = [];
  children = [];
}
