import React from 'react';
import {observer} from "mobx-react-lite";
import appStore from "../../storage/AppStore";
import {useLocation} from 'react-router'

const Footer = () => {
  const location = useLocation();
  return (
      <div>
        {/* Page footer */}
        <footer className="footer bg-dark pb-4" data-bs-theme="dark">
          <div className="container pb-md-3">
            {/*/!* Features *!/*/}
            {/*<div className="border-bottom py-5">*/}
            {/*  <div className="row row-cols-2 row-cols-md-4 g-4 gx-sm-5 py-sm-1 py-md-2 py-lg-3 mb-n2 mb-md-0">*/}
            {/*    <div className="col mb-2 mb-md-0">*/}
            {/*      <i className="ci-layers-2 fs-xl text-dark-emphasis mb-3"/>*/}
            {/*      <h6 className="pb-2 mb-1">Regularly updated content</h6>*/}
            {/*      <p className="fs-sm text-body mb-0">Stay ahead of trends, always having fresh and modern assets.</p>*/}
            {/*    </div>*/}
            {/*    <div className="col mb-2 mb-md-0">*/}
            {/*      <i className="ci-click fs-xl text-dark-emphasis mb-3"/>*/}
            {/*      <h6 className="pb-2 mb-1">Subscription-based access</h6>*/}
            {/*      <p className="fs-sm text-body mb-0">Find everything you need in one place, saving time and effort.</p>*/}
            {/*    </div>*/}
            {/*    <div className="col mb-2 mb-md-0">*/}
            {/*      <i className="ci-grid-2 fs-xl text-dark-emphasis mb-3"/>*/}
            {/*      <h6 className="pb-2 mb-1">Exclusive collections</h6>*/}
            {/*      <p className="fs-sm text-body mb-0">Partner with renowned designers and artists to create exclusive*/}
            {/*        collections.</p>*/}
            {/*    </div>*/}
            {/*    <div className="col mb-2 mb-md-0">*/}
            {/*      <i className="ci-check-search fs-xl text-dark-emphasis mb-3"/>*/}
            {/*      <h6 className="pb-2 mb-1">User-friendly search </h6>*/}
            {/*      <p className="fs-sm text-body mb-0">Spend less time searching and more time creating.</p>*/}
            {/*    </div>*/}
            {/*  </div>*/}
            {/*</div>*/}
            {/*/!* Subscription + Links *!/*/}
            {/*<div className="py-5">*/}
            {/*  <div className="row py-sm-1 py-md-2 py-lg-3">*/}
            {/*    /!* Subscription + Social buttons *!/*/}
            {/*    <div className="col-lg-5 mb-4 mb-sm-5 mb-lg-0">*/}
            {/*      <h6 className="mb-4">Join our newsletter, get discounts 🔥</h6>*/}
            {/*      <form className="needs-validation d-flex gap-2 pb-sm-2 pb-lg-0 mb-4 mb-lg-5" noValidate>*/}
            {/*        <input type="email" className="form-control form-control-lg w-100 rounded-pill"*/}
            {/*               placeholder="Your email" style={{"maxWidth": "340px"}} required/>*/}
            {/*        <button type="submit" className="btn btn-lg btn-primary rounded-pill">Subscribe</button>*/}
            {/*      </form>*/}
            {/*      <div className="d-flex gap-3">*/}
            {/*        <a className="btn btn-icon btn-secondary rounded-circle" href="#!" aria-label="Instagram">*/}
            {/*          <i className="ci-instagram fs-base"/>*/}
            {/*        </a>*/}
            {/*        <a className="btn btn-icon btn-secondary rounded-circle" href="#!" aria-label="Facebook">*/}
            {/*          <i className="ci-facebook fs-base"/>*/}
            {/*        </a>*/}
            {/*        <a className="btn btn-icon btn-secondary rounded-circle" href="#!" aria-label="YouTube">*/}
            {/*          <i className="ci-youtube fs-base"/>*/}
            {/*        </a>*/}
            {/*        <a className="btn btn-icon btn-secondary rounded-circle" href="#!" aria-label="Telegram">*/}
            {/*          <i className="ci-telegram fs-base"/>*/}
            {/*        </a>*/}
            {/*      </div>*/}
            {/*    </div>*/}
            {/*    /!* Columns with links that are turned into accordion on screens < 500px wide (sm breakpoint) *!/*/}
            {/*    <div className="col-lg-7">*/}
            {/*      <div className="accordion ps-lg-4" id="footerLinks">*/}
            {/*        <div className="row">*/}
            {/*          <div className="accordion-item col-sm-4 border-0">*/}
            {/*            <h6 className="accordion-header" id="categoriesHeading">*/}
            {/*              <span className="text-dark-emphasis d-none d-sm-block">Categories</span>*/}
            {/*              <button type="button" className="accordion-button collapsed py-3 d-sm-none"*/}
            {/*                      data-bs-toggle="collapse" data-bs-target="#categoriesLinks" aria-expanded="false"*/}
            {/*                      aria-controls="categoriesLinks">Categories*/}
            {/*              </button>*/}
            {/*            </h6>*/}
            {/*            <div className="accordion-collapse collapse d-sm-block" id="categoriesLinks"*/}
            {/*                 aria-labelledby="categoriesHeading" data-bs-parent="#footerLinks">*/}
            {/*              <ul className="nav flex-column gap-2 pt-sm-3 pb-3 pb-sm-0 mt-n1 mb-1 mb-sm-0">*/}
            {/*                <li className="d-flex w-100 pt-1">*/}
            {/*                  <a className="nav-link animate-underline animate-target d-inline fw-normal text-truncate p-0"*/}
            {/*                     href="#!">Vectors</a>*/}
            {/*                </li>*/}
            {/*                <li className="d-flex w-100 pt-1">*/}
            {/*                  <a className="nav-link animate-underline animate-target d-inline fw-normal text-truncate p-0"*/}
            {/*                     href="#!">Photos</a>*/}
            {/*                </li>*/}
            {/*                <li className="d-flex w-100 pt-1">*/}
            {/*                  <a className="nav-link animate-underline animate-target d-inline fw-normal text-truncate p-0"*/}
            {/*                     href="#!">3D illustrations</a>*/}
            {/*                </li>*/}
            {/*                <li className="d-flex w-100 pt-1">*/}
            {/*                  <a className="nav-link animate-underline animate-target d-inline fw-normal text-truncate p-0"*/}
            {/*                     href="#!">AI images</a>*/}
            {/*                </li>*/}
            {/*                <li className="d-flex w-100 pt-1">*/}
            {/*                  <a className="nav-link animate-underline animate-target d-inline fw-normal text-truncate p-0"*/}
            {/*                     href="#!">Templates</a>*/}
            {/*                </li>*/}
            {/*                <li className="d-flex w-100 pt-1">*/}
            {/*                  <a className="nav-link animate-underline animate-target d-inline fw-normal text-truncate p-0"*/}
            {/*                     href="#!">Mockups</a>*/}
            {/*                </li>*/}
            {/*              </ul>*/}
            {/*            </div>*/}
            {/*            <hr className="d-sm-none my-0"/>*/}
            {/*          </div>*/}
            {/*          <div className="accordion-item col-sm-4 border-0">*/}
            {/*            <h6 className="accordion-header" id="membersHeading">*/}
            {/*              <span className="text-dark-emphasis d-none d-sm-block">For members</span>*/}
            {/*              <button type="button" className="accordion-button collapsed py-3 d-sm-none"*/}
            {/*                      data-bs-toggle="collapse" data-bs-target="#membersLinks" aria-expanded="false"*/}
            {/*                      aria-controls="membersLinks">For members*/}
            {/*              </button>*/}
            {/*            </h6>*/}
            {/*            <div className="accordion-collapse collapse d-sm-block" id="membersLinks"*/}
            {/*                 aria-labelledby="membersHeading" data-bs-parent="#footerLinks">*/}
            {/*              <ul className="nav flex-column gap-2 pt-sm-3 pb-3 pb-sm-0 mt-n1 mb-1 mb-sm-0">*/}
            {/*                <li className="d-flex w-100 pt-1">*/}
            {/*                  <a className="nav-link animate-underline animate-target d-inline fw-normal text-truncate p-0"*/}
            {/*                     href="#!">Licenses</a>*/}
            {/*                </li>*/}
            {/*                <li className="d-flex w-100 pt-1">*/}
            {/*                  <a className="nav-link animate-underline animate-target d-inline fw-normal text-truncate p-0"*/}
            {/*                     href="#!">Return policy</a>*/}
            {/*                </li>*/}
            {/*                <li className="d-flex w-100 pt-1">*/}
            {/*                  <a className="nav-link animate-underline animate-target d-inline fw-normal text-truncate p-0"*/}
            {/*                     href="#!">Payment methods</a>*/}
            {/*                </li>*/}
            {/*                <li className="d-flex w-100 pt-1">*/}
            {/*                  <a className="nav-link animate-underline animate-target d-inline fw-normal text-truncate p-0"*/}
            {/*                     href="#!">Become a vendor</a>*/}
            {/*                </li>*/}
            {/*              </ul>*/}
            {/*            </div>*/}
            {/*            <hr className="d-sm-none my-0"/>*/}
            {/*          </div>*/}
            {/*          <div className="accordion-item col-sm-4 border-0">*/}
            {/*            <h6 className="accordion-header" id="supportHeading">*/}
            {/*              <span className="text-dark-emphasis d-none d-sm-block">Support</span>*/}
            {/*              <button type="button" className="accordion-button collapsed py-3 d-sm-none"*/}
            {/*                      data-bs-toggle="collapse" data-bs-target="#supportLinks" aria-expanded="false"*/}
            {/*                      aria-controls="supportLinks">Support*/}
            {/*              </button>*/}
            {/*            </h6>*/}
            {/*            <div className="accordion-collapse collapse d-sm-block" id="supportLinks"*/}
            {/*                 aria-labelledby="supportHeading" data-bs-parent="#footerLinks">*/}
            {/*              <ul className="nav flex-column gap-2 pt-sm-3 pb-3 pb-sm-0 mt-n1 mb-1 mb-sm-0">*/}
            {/*                <li className="d-flex w-100 pt-1">*/}
            {/*                  <a className="nav-link animate-underline animate-target d-inline fw-normal text-truncate p-0"*/}
            {/*                     href="#!">FAQs</a>*/}
            {/*                </li>*/}
            {/*                <li className="d-flex w-100 pt-1">*/}
            {/*                  <a className="nav-link animate-underline animate-target d-inline fw-normal text-truncate p-0"*/}
            {/*                     href="#!">Search guide</a>*/}
            {/*                </li>*/}
            {/*                <li className="d-flex w-100 pt-1">*/}
            {/*                  <a className="nav-link animate-underline animate-target d-inline fw-normal text-truncate p-0"*/}
            {/*                     href="#!">Contact</a>*/}
            {/*                </li>*/}
            {/*              </ul>*/}
            {/*            </div>*/}
            {/*            <hr className="d-sm-none my-0"/>*/}
            {/*          </div>*/}
            {/*        </div>*/}
            {/*      </div>*/}
            {/*    </div>*/}
            {/*  </div>*/}
            {/*</div>*/}
            {/* Copyright */}
            <p className="fs-xs text-body text-center text-lg-start mb-0 pt-3">
              © Copyright. <a className="animate-target text-white text-decoration-none"
                                                 href="https://sooda.online/">Sooda Online</a>
            </p>
          </div>
        </footer>
        {location.pathname.includes("category")  &&
          <button type="button"
                  className="fixed-bottom z-sticky w-100 btn btn-lg bg-body border-0 border-top border-light border-opacity-10 rounded-0 pb-4 d-lg-none"
                  data-bs-toggle="offcanvas" data-bs-target="#filterSidebar" aria-controls="filterSidebar">
            <i className="ci-filter fs-base me-2">&nbsp;</i>
            {appStore.tr.filter}
          </button>
        }
        <input type="checkbox" className="d-none form-check-input" role="switch" id="rtl-switch"/>
      </div>
  )
}

export default observer(Footer);