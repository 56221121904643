import React, {useEffect} from "react";
import {observer} from "mobx-react-lite";
import {Link, useParams} from "react-router";
import marketStore from "../../storage/MarketStore";
import Slider from './Slider'
import Breadcrumb from '../Category/Breadcrumb'
import appStore from '../../storage/AppStore'

const Product = () => {
  const args = useParams();
  useEffect(() => {
    marketStore.getProduct(args.id).then(() => {
      console.log('product', marketStore.product)
      marketStore.getCategory(marketStore.product.category?.id)
    })
  }, [args]);
  return (
    <>
      <Breadcrumb />
      <div>
        <h5>{marketStore.product.title}</h5>
      </div>
      
      <div className="animate-underline hover-effect-opacity hover-effect-scale rounded-4 overflow-hidden">
        <Slider/>
      </div>
      
      <div className="my-4" style={{"textAlign": "justify", "textJustify": "inter-word"}}>
        <p>{marketStore.product.description}</p>
      </div>
      
      <table className="table table-borderless table-sm mb-0">
        <tbody>
        <tr>
          <td className="text-body-secondary ps-0">Дата</td>
          <td className="text-dark-emphasis pe-0"> {marketStore.product.updated_at ? marketStore.product.updated_at : marketStore.product.created_at}</td>
        </tr>
        <tr>
          <td className="text-body-secondary ps-0">{appStore.tr.price}</td>
          <td className="text-dark-emphasis pe-0">{marketStore.product.price ? marketStore.product.price + " " + marketStore.product.currency : appStore.tr.negotiable_price} {marketStore.product.old_price ? <span className="text-decoration-line-through text-muted">{marketStore.product.old_price + " " + marketStore.product.currency}</span> : null}</td>
        </tr>
        {marketStore.product.properties.map((property, index) => (
          <tr key={index}>
            <td className="text-body-secondary ps-0">{property.name}</td>
            <td className="text-dark-emphasis pe-0">{property.value}</td>
          </tr>
        ))}
        <tr>
          <td className="text-body-secondary ps-0">{appStore.tr.location}</td>
          <td className="text-dark-emphasis pe-0">{marketStore.product.country?.name}, {marketStore.product.region?.name}, {marketStore.product.district?.name}</td>
        </tr>
        <tr>
          <td className="text-body-secondary ps-0">Телефон</td>
          <td className="text-dark-emphasis pe-0"><Link to={`https:/t.me/${marketStore.product.user?.phone}`} target="_blank">{marketStore.product.user?.phone}</Link></td>
        </tr>
        </tbody>
      </table>
    </>
  )
}

export default observer(Product);